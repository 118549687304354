import {
  AppBar,
  Logo,
  React,
  RouterLink,
  ThemeType,
  Toolbar,
  makeStyles,
} from "ui-kit-legacy";

interface TopBarProps {
  className?: string;
}

export const APP_TOP_BAR_HEIGHT = 54;

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main,
    height: APP_TOP_BAR_HEIGHT,
  },
  toolbar: {
    minHeight: APP_TOP_BAR_HEIGHT,
  },
}));

const AppTopBar = ({ ...rest }: TopBarProps) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo width={150} />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

AppTopBar.defaultProps = {
  className: undefined,
};

export default AppTopBar;
