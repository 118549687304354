import type { ReactNode, ThemeType } from "ui-kit-legacy";
import { React, makeStyles } from "ui-kit-legacy";

import AppTopBar, { APP_TOP_BAR_HEIGHT } from "../../components/AppTopBar";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: APP_TOP_BAR_HEIGHT,
  },
  content: {
    overflowY: "scroll",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppTopBar />
      <div className={classes.wrapper}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

DashboardLayout.defaultProps = {
  children: null,
};

export default DashboardLayout;
