import { ApolloProvider } from "@apollo/client";
import { React, RenderRoutes, getApolloClient } from "ui-kit-legacy";

import SuspenseLayout from "./components/SuspenseLayout";
import config from "./config";
import routes from "./routing";

const { GRAPHQL_URL, DEBUG } = config;

const RenderAllRoutes = () => (
  <RenderRoutes routes={routes} FallbackLayoutComponent={SuspenseLayout} />
);

export default function App() {
  return (
    <ApolloProvider
      client={getApolloClient({
        token: "",
        uri: GRAPHQL_URL,
        connectToDevTools: DEBUG,
        name: "onboarding",
      })}
    >
      <RenderAllRoutes />
    </ApolloProvider>
  );
}
